<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="null"
        :customSearchList="searchType.list"
        :searchObj="searchType.obj"
        @change-search-type="$_changeSearchType"
        @onSearchClick="searchData($event)"
      >
      </Search>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="deviceGrid" />
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      Search,
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
          gradeList: null,
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          list: [
            {
              codeNm: '이름',
              codeValue: 'userNm',
            },
            {
              codeNm: '사번',
              codeValue: 'userId',
            },
            {
              codeNm: '부서명',
              codeValue: 'deptNm',
            },
          ],
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {},
          paramsData: null,
        },
        cmList: [],
        stateList: [],
        dataGrid: {
          excel: {
            // title: '전화 정보 목록',
            title: this.$_msgContents('UC.WORD.DEVICE_INFO', { defaultValue: '전화 정보' }),
          },
          refName: 'deviceGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height: '500', // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            // cellPrepared: true,
          },
          showActionButtons: {
            excel: false, // 엑셀 버튼
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '사번',
              dataField: 'userId',
              //   width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporting: true,
              //   cellTemplate: async (container, options) => {
              //     const imgTag = document.createElement('img');
              //     // const imgData = this.$_getAttachFileURL(null, options.value);
              //     const imgData = this.$_getAttachFileURL(null, options.data.product);
              //     imgTag.setAttribute('src', imgData);
              //     container.append(imgTag);
              //   },
            },
            {
              caption: '이름',
              dataField: 'userNm',
              //   width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporting: false,
              //   cellTemplate: (container, options) => {
              //     const aTag = document.createElement('a');
              //     aTag.innerText = options.value;
              //     aTag.addEventListener('click', async () => {
              //       const type = 'device';
              //       await this.onSelectData(options.data, type);
              //     });
              //     container.append(aTag);
              //   },
            },
            {
              caption: '부서명',
              dataField: 'deptNm',
              //   width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporting: true,
              //   calculateDisplayCellValue: rowData => {
              //     if (rowData?.cmNm) {
              //       return this.getCmNm(rowData.cmNm);
              //     }
              //     if (rowData.cmNm == null || rowData.cmNm == 'null') {
              //       return '';
              //     }
              //   },
            },
            {
              caption: '전화번호',
              dataField: 'deviceDn',
              // width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              allowExporting: true,
            },
            {
              caption: '장치이름',
              dataField: 'deviceMac',
              //   width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              allowExporting: true,
            },

            {
              caption: '상태',
              dataField: 'deviceSt',
              //   width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              allowExporting: true,
              calculateDisplayCellValue: rowData => {
                if (rowData?.deviceSt) {
                  let data = this.stateList.filter(d => d.codeValue == rowData.deviceSt)[0].description;
                  return data;
                }
                if (rowData.deviceSt == null || rowData.deviceSt == 'null') {
                  return '';
                }
              },
            },
            {
              caption: '직원IP',
              dataField: 'userIp',
              //   width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporting: true,
            },
            {
              caption: '서버IP',
              dataField: 'serverIp',
              //   width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporting: true,
              fixed: false,
              fixedPosition: 'left',
              allowExporting: true,
            },
            {
              caption: '상태변경시간',
              dataField: 'stChTm',
              //   width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatStDt,
              allowExporting: true,
            },
            {
              caption: '로그인시간',
              dataField: 'loginTm',
              //   width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
              allowExporting: true,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.selectIptCode();
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountData() {
        // await this.selectCmList();
        await this.selectDataList();
      },

      /**@description 커스텀 검색 이벤트 */
      searchData(paramsData) {
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          const codeValues = this.searchType.list.map(d => d.codeValue);
          if (this.searchType.paramsData != null) {
            if (Object.keys(this.searchType.paramsData).length > 0) {
              Object.keys(this.searchType.paramsData).forEach(key => {
                if (codeValues.includes(key)) {
                  delete this.searchType.paramsData[key];
                }
              });
            }
          }
        }
        this.selectDataList();
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = 'userId') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'userId',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }
            params = {
              ...params,
              ...this.searchType.paramsData,
              ...this.searchType.customTypes,
            };

            const payload = {
              actionname: 'EUC_USER_STATE_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /**@description IPT CODE 가져오는 메서드 */
      async selectIptCode(sort = 'codeOrd') {
        const params = { sort: sort, codeKey: 'devicestate', viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CODE_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.stateList = res.data.data;
          for (let i = 0; i < this.stateList.length; i++) {
            if (this.stateList[i].parentId == null) {
              this.stateList.splice(i, 1);
            }
          }
        }
      },
      formatStDt(rowData) {
        if (rowData?.stChTm) {
          return this.$_commonlib.formatDate(rowData.stChTm, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.loginTm) {
          return this.$_commonlib.formatDate(rowData.loginTm, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
    },

    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
